import './Footer.scss';

// Media
import strategicResourceLogo from './../../assets/img/StrategicResourceLogo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookSquare, faTwitterSquare, faYoutubeSquare } from '@fortawesome/free-brands-svg-icons';
import { Link } from 'react-router-dom';
import { footerMenu } from '../appMenus';

export default function Footer(props) {

    return (
        <div className="footer-wrapper">
            <div className="left-col">
                <img src={strategicResourceLogo}/>
                <div className="footnote">
                    The Strategic Resource is a coaching and
                    leadership resource for real estate sales
                    professionals, broker and managers,
                    team leaders, and property managers.
                </div>
                <div className="copyright">
                    <span>© 2020</span>
                    <span className="social-icons">
                        <a target="_blank" className="facebook" href="https://www.facebook.com/thestrategicresource"><FontAwesomeIcon icon={faFacebookSquare} /></a>
                        <a target="_blank" className="twitter" href="https://twitter.com/TSRRealEstate"><FontAwesomeIcon icon={faTwitterSquare}/></a>
                        {/* <a target="_blank" className="youtube" href="https://www.youtube.com/channel/UCSpKEuq_icTO9uzDEy7Q69g?"><FontAwesomeIcon icon={faYoutubeSquare}/></a> */}
                    </span>
                </div>
            </div>
            <div className="right-col">
            <ul id="menu-footer" className="menu">
                {footerMenu.map((el, i) => {
                    if(el.external) {
                        return <li key={el.uid} className='menu-item'>
                                <a href={el.link} className="title" target="_blank">{el.label}</a>
                                {el.submenu && (
                                    <ul className='sub-menu'>
                                        {el.submenu.map((item, index) => {
                                        return <li key={item.uid} className='menu-item'>
                                            {item.external && (<a href={item.link} target="_blank">{item.label}</a>)}
                                            {!item.external && (<Link to={item.link}>{item.label}</Link>)}
                                        </li>
                                        })}
                                    </ul>
                                )}
                            </li>
                    } else {
                        return <li key={el.uid} className='menu-item'>
                                <Link className="title" to={el.link}>{el.label}</Link>
                                {el.submenu && (
                                    <ul className='sub-menu'>
                                        {el.submenu.map((item, index) => {
                                        return <li key={item.uid} className='menu-item'>
                                            {item.external && (<a href={item.link} target="_blank">{item.label}</a>)}
                                            {!item.external && (<Link to={item.link}>{item.label}</Link>)}
                                        </li>
                                        })}
                                    </ul>
                                )}
                            </li>
                    }
                })}
                </ul>
            </div>
        </div>
    );
}