import './App.css';
import { Routes, Route } from 'react-router-dom';

import Home from './pages/Home/Home';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import AboutChris from './pages/AboutChris/AboutChris';
import StrategyCall from './pages/StrategyCall/StrategyCall';
import PodcastSignup from './pages/PodcastSignup/PodcastSignup';

function App() {
  return (
    <div className="App">
      <Header/>
      <Routes>
        <Route path="/" element={<Home/>}/>
        <Route path="/about-chris" element={<AboutChris/>}/>
        <Route path="/strategy-call" element={<StrategyCall/>}/>
        <Route path="/podcast-signup" element={<PodcastSignup/>}/>
      </Routes>
      <Footer/>
    </div>
  );
}

export default App;
