import './PodcastSignup.scss';
import deskBackground from  './../../assets/img/podcast-signup-mic.jpg';
import Button from '../../components/Button/Button';
import { useEffect } from 'react';

export default function PodcastSignup() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="podcast-signup-page">
            <div className="header bkg-blue">
                <h1>Be A Guest On The Connect, Practice, <br/> Track & Grow Podcast!</h1>
            </div>
            <div className="content">
                <div className="inner-content">
                    <h4>Join Chris McAllister for a live look at your business
                        model and quick, success-oriented feedback.
                    </h4>
                    <p>There is nothing I like doing more than working one on one 
                        with successful real estate professionals and helping them 
                        realize bigger futures for themselves and their clients.
                    </p>
                    <p>Join me on my podcast and uncover the unique opportunities 
                        and advantages available to you in your market. 
                    </p>
                    <p>Using the <strong>Eight Connect Practice Track & Grow Collaboration 
                        Accelerators</strong>, we will build a plan, and a new path forward, 
                        just for you.
                    </p>
                    <div className='end-button'>
                        <Button>Sign Up Now!</Button>
                    </div>
                </div>
                <img src={deskBackground}/>
            </div>
        </div>
    )
}