import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserTie, faPhone, faComments } from '@fortawesome/free-solid-svg-icons';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';
import './AboutChris.scss';

// Media
import chrisFull from './../../assets/img/chris-full-image.jpg';

export default function AboutChris(props) {
    window.scrollTo(0, 0);

    let questions = [
        {
            question: 'How long have you been a part of the ROOST team?',
            answer: 'We formed ROOST in January of 2014. Prior to that I owned another real estate franchise and worked with a local real estate firm.'
        },
        {
            question: 'What\'s the number one myth that you hear about the Real Estate market that you\'d like to clear up?',
            answer: 'It\'s a myth that the market is in bad shape right now. Well cared for houses priced right are selling very well right now and will continue to.'
        },
        {
            question: 'Favorite part about helping people find their next home or sell their current home?',
            answer: 'The best part of my job is helping people list and sell the home they currently live in while at the same time helping them find and purchase their new home. There are a lot of moving parts that have to be coordinated but my job is to make the complex as simple and stress-free as possible.'
        },
        {
            question: 'In your opinion what makes ROOST a different kind of real estate company?',
            answer: 'We built this brand to be Smart, Passionate, Supportive, and Approachable. ROOST is the real estate company I always wanted to work for.'
        },
        {
            question: 'What is the one thing you would tell someone who is hesitant to list their home out of fear of the market?',
            answer: 'Many people think they still owe too much on their current mortgage to be able to successfully list, sell and move to a new home. The market has changed a lot in the last few months and they owe it to themselves to have qualified professional take another look at their situation. Give us a shout - it costs you nothing and you may be surprised.'
        },
        {
            question: 'When you\'re not working at ROOST what hobby occupies your time?',
            answer: 'I have to admit dreaming about what ROOST is going to look like in the next five to ten years takes up a lot of my time. I do however love to run trails, ride my bike, and take beach vacations. My wife Kelly and I go to the movies almost every Saturday afternoon as well.'
        },
        {
            question: 'What is one thing that most people don\'t know about you?',
            answer: 'Kelly and I have been married for almost 28 years and we have three grand-daughters. Gretchen who is our Property Manager at ROOST and Sara who is studying to be a chef in Columbus.'
        },
        {
            question: 'Coffee or Tea?',
            answer: 'Coffee.'
        },
        {
            question: 'Cats or Dogs?',
            answer: 'We have both in the house.'
        },
        {
            question: 'Favorite movie? Sports team? Band?',
            answer: 'I really enjoyed the new Richard Linklater movie, Boyhood, this year. Definitely in my top 10 of all time. If you love movies this one is a winner.'
        }
    ];

    function separateQuestions() {
        let counter = 1;
        let arrays = [[], [], []];
        questions.map((el, index) => {
            el.index = index + 1;
            arrays[counter - 1].push(el);
            if(counter == 3) {
                counter = 1;
            } else {
                counter++;
            }
        });
        return arrays;
    }

    return (
        <div className="chris-page">
            <div className="page-header">
                <div className="header-content">
                    <h1>Chris McAllister</h1>
                    <p>
                        <FontAwesomeIcon icon={faUserTie}/>
                        Founder / President / Realtor / Broker
                    </p>
                    <div className="profile-image">
                        <div className="image-wrapper">
                            <img src={chrisFull}/>
                            <div className="contact-data">
                                <h6>Let's Chat</h6>
                                <ul>
                                    <li className="phone">
                                        <FontAwesomeIcon icon={faPhone}/>
                                        <a href="tel:8448066577">(844) 806-6577</a>
                                    </li>
                                    <li className="envelope">
                                        <FontAwesomeIcon icon={faEnvelope}/>
                                        <a href="mailto:chris@roostrealestateco.com">Chris@ROOSTRealEstateCo.com</a>
                                    </li>
                                    <li className="facebook">
                                        <FontAwesomeIcon icon={faFacebook}/>
                                        <a href="https://www.facebook.com/Chris-McAllister-Agent-at-ROOST-Real-Estate-Co-2058282584443131/" target="_blank">Find me on Facebook</a>
                                    </li>
                                </ul>
                                <div className="comments">
                                    <FontAwesomeIcon icon={faComments}/>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
            <div className="page-content">
                <div className="text">
                    <p>Chris was first licensed as a real estate agent in 2001 in Ohio. He became a broker in 2003 and founded ROOST Real Estate Co. in early 2014.</p>
                    <p>Chris is also a licensed real estate broker in Florida and Tennessee.</p>
                    <p>Chris's passion is creating and coaching business opportunities and strategies that support and add value to real estate professionals and their clients.</p>
                    <p>He is the author of several books on real estate including his latest, The Landlord Profitability Playbook - The Eight Profitability Plays You Need To Automate Property Management And Get On With Your Life. All are available on his Amazon author page at <a href="http://www.CMcAllisterAmazon.com" target="_blank">www.CMcAllisterAmazon.com</a>.</p>
                    <p>Chris has been married to his wife Kelly for over 30 years and has two adult daughters and three grand-daughters who know him as 'Granddude'.</p>
                    <p>Chris and Kelly split their time between Columbus, Ohio and Melbourne, Florida, but love spending time anywhere there are ROOST Real Estate Co. friends nearby.</p>
                    <p>Chris enjoys endurance training while listening to audiobooks and podcasts, cooking, movies, the beach, and spending time with his family.</p>
                </div>
            </div>
            <div className="questions-container">
                <h1>10 Questions with Chris</h1>
                <div className="qna-grid">
                    {separateQuestions().map((el, index) => <div key={index} className="qna-grid-column">
                        {el.map((q, i) => <div key={i} className="questions-container">
                                <div className="question-index">{q.index}</div>
                                <div className="question">{q.question}</div>
                                <div className="answer">{q.answer}</div>
                            </div>)}
                    </div>)}
                </div>
                <div className="qna-list">
                    {questions.map((q, i) => <div key={i} className="questions-container">
                        <div className="question-index">{i + 1}</div>
                        <div className="question">{q.question}</div>
                        <div className="answer">{q.answer}</div>
                    </div>)}
                </div>
            </div>
        </div>
    )
}