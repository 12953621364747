import "./Header.scss";
import headerBanner from "./../../assets/img/header-banner.jpeg";
import chevron from "./../../assets/img/chevron.png";
import { Link } from "react-router-dom";
import { menu } from '../appMenus';
import menuIcon from './../../assets/img/menu.png';
import { useState } from "react";

export default function Header(props) {

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  function toggleMenu() {
    setMobileMenuOpen(!mobileMenuOpen);
  }

  return (
    <div className="header">
      <img src={headerBanner} />
      <div className="site-menu">
        {menu.map((el, index) => {
          if (el.external) {
            return (
              <div
                key={el.uid}
                className={
                  el?.class +
                  " menu-item " +
                  (el?.submenu?.length > 0 ? " with-submenu" : "")
                }
              >
                <a href={el.link} target="_blank">{el.label}</a>
                {el?.submenu?.length > 0 && (
                  <img className="chevron" src={chevron} />
                )}
                {el?.submenu?.length > 0 && (
                  <div className="submenu-wrapper">
                    <div>
                    {el.submenu.map((el, index) => {
                      if(el.external == true) {
                        return <a key={el.uid} href={el.link} target="_blank">{el.label}</a>
                      } else {
                        return <Link key={el.uid} to={el.link}>{el.label}</Link>
                      }
                    })}
                    </div>
                  </div>
                )}
              </div>
            );
          } else {
            return (
              <div
                key={el.uid}
                className={[
                  el?.class ? el?.class : null,
                  "menu-item",
                  el?.submenu?.length > 0 ? "with-submenu" : null,
                ].join(" ")}
              >
                <Link to={el.link}>
                  {el.label}
                  {el?.submenu?.length > 0 && (
                    <img className="chevron" src={chevron} />
                  )}
                </Link>
                {el?.submenu?.length > 0 && (
                  <div className="submenu-wrapper">
                    <div>
                    {el.submenu.map((el, index) => {
                      if(el.external == true) {
                        return <a key={el.uid} href={el.link} target="_blank">{el.label}</a>
                      } else {
                        return <Link key={el.uid} to={el.link}>{el.label}</Link>
                      }
                    })}
                    </div>
                  </div>
                )}
              </div>
            );
          }
        })}
      </div>
      <div className={`mobile-site-menu` + (mobileMenuOpen ? ` active` : '')}>
        <div className="mobile-menu-toggler">
          <img src={menuIcon} onClick={toggleMenu}/>
        </div>
        <div className="menu-content">
          {menu.map((el, index) => {
            if (el.external) {
              return (
                <div
                  key={el.uid}
                  className={
                    el?.class +
                    " menu-item " +
                    (el?.submenu?.length > 0 ? " with-submenu" : "")
                  }
                >
                  <a href={el.link}>{el.label}</a>
                  {el?.submenu?.length > 0 && (
                    <div className="submenu-wrapper">
                      <div>
                      {el.submenu.map((el, index) => {
                        if(el.external == true) {
                          return <a key={el.uid} href={el.link}>{el.label}</a>
                        } else {
                          return <Link key={el.uid} to={el.link}>{el.label}</Link>
                        }
                      })}
                      </div>
                    </div>
                  )}
                </div>
              );
            } else {
              return (
                <div
                  key={el.uid}
                  className={[
                    el?.class ? el?.class : null,
                    "menu-item",
                    el?.submenu?.length > 0 ? "with-submenu" : null,
                  ].join(" ")}
                >
                  <Link to={el.link}>
                    {el.label}
                  </Link>
                  {el?.submenu?.length > 0 && (
                    <div className="submenu-wrapper">
                      <div>
                      {el.submenu.map((el, index) => {
                        if(el.external == true) {
                          return <a key={el.uid} href={el.link}>{el.label}</a>
                        } else {
                          return <Link key={el.uid} to={el.link}>{el.label}</Link>
                        }
                      })}
                      </div>
                    </div>
                  )}
                </div>
              );
            }
        })}
        </div>
      </div>
    </div>
  );
}
